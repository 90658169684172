export default ({ instance }) => {
	return {
		//Dashboard

		ExportardadosCompletosExcelAndamento(filtro) {
			return instance({
				responseType: "blob",
				method: "post",
				url: "homeandamento/exportar-tabela-excel",
				data: filtro,
			});
		},

		relatorioMesAnoPorFiltroAndamento(filtro) {
			return instance({
				method: "post",
				url: "homeandamento/mes-ano-filtro",
				data: filtro,
			});
		},

		relatorioClassificacaoPorFiltroAndamento(filtro) {
			return instance({
				method: "post",
				url: "homeandamento/classificacao-filtro",
				data: filtro,
			});
		},

		relatorioGrupoOperacionalPorFiltroAndamento(filtro) {
			return instance({
				method: "post",
				url: "homeandamento/grupo-operacional-filtro",
				data: filtro,
			});
		},

		buscarSubGrupoAndamento(grupoOperacional) {
			return instance({
				method: "get",
				url: "homeandamento/" + grupoOperacional,
			});
		},

		relatorioPorJusticaAndamento(filtro) {
			return instance({
				method: "post",
				url: "homeandamento/justica",
				data: filtro,
			});
		},

		relatorioPorMesAnoAndamento(filtro) {
			return instance({
				method: "post",
				url: "homeandamento/mes-ano",
				data: filtro,
			});
		},

		relatorioPorUfAndamento(filtro) {
			return instance({
				method: "post",
				url: "homeandamento/uf",
				data: filtro,
			});
		},

		relatorioPorClassificacaoAndamento(filtro) {
			return instance({
				method: "post",
				url: "homeandamento/classificacao",
				data: filtro,
			});
		},

		//Processos Monitorados
		consultarProcessosMonitoradosAndamento(filtro) {
			return instance({
				method: "post",
				url: "processosmonitoradosandamento/processos-monitorados",
				data: filtro,
			});
		},

		exportarProcessosMonitoradosAndamento(filtro) {
			return instance({
				responseType: "blob",
				method: "post",
				url: "processosmonitoradosandamento/exportar-processos-monitorados",
				data: filtro,
			});
		},

		//Andamentos
		andamentosProcessos(filtros) {
			return instance({
				method: "post",
				url: "andamentos/processos",
				data: filtros,
			});
		},
		andamentosExportarProcessosExcel(filtro) {
			return instance({
				responseType: "blob",
				method: "post",
				url: "andamentos/exportar-processos-excel",
				data: filtro,
			});
		},
		andamentosExportarProcessosJson(filtro) {
			return instance({
				method: "post",
				url: "andamentos/exportar-processos-json",
				data: filtro,
			});
		},
		andamentosExportarProcessosXml(filtro) {
			return instance({
				responseType: "blob",
				method: "post",
				url: "andamentos/exportar-processos-xml",
				data: filtro,
			});
		},
		andamentosExportarProcessosDocx(filtro) {
			return instance({
				responseType: "blob",
				method: "post",
				url: "andamentos/exportar-processos-docx",
				data: filtro,
			});
		},
		andamentosDetalheAndamento(codigo) {
			return instance({
				method: "post",
				url: "andamentos/detalhe-andamento",
				data: codigo,
			});
		},
	};
};
