export default ({ instance }) => {
	return {
		//Dashboard
		listarGrupoOperacionalJuridico(filtro) {
			return instance({
				method: "post",
				url: "homejuridico/grupo-operacional",
				data: filtro,
			});
		},

		relatorioMesAnoPorFiltroJuridico(filtro) {
			return instance({
				method: "post",
				url: "homejuridico/mes-ano-filtro",
				data: filtro,
			});
		},

		relatorioTermoPorFiltroJuridico(filtro) {
			return instance({
				method: "post",
				url: "homejuridico/termo-filtro",
				data: filtro,
			});
		},

		relatorioGrupoOperacionalPorFiltroJuridico(filtro) {
			return instance({
				method: "post",
				url: "homejuridico/grupo-operacional-filtro",
				data: filtro,
			});
		},

		ExportardadosCompletosExcelJuridico(filtro) {
			return instance({
				responseType: "blob",
				method: "post",
				url: "homejuridico/exportar-tabela-excel",
				data: filtro,
			});
		},

		relatorioPorJusticaJuridico(filtro) {
			return instance({
				method: "post",
				url: "homejuridico/justica",
				data: filtro,
			});
		},

		relatorioPorUfJuridico(filtro) {
			return instance({
				method: "post",
				url: "homejuridico/uf",
				data: filtro,
			});
		},

		relatorioPorTermoJuridico(filtro) {
			return instance({
				method: "post",
				url: "homejuridico/termo",
				data: filtro,
			});
		},

		relatorioPorMesAnoJuridico(filtro) {
			return instance({
				method: "post",
				url: "homejuridico/mes-ano",
				data: filtro,
			});
		},

		//Dados Cadastrais
		dadosCadastraisJuridico(dadosCadastrais) {
			return instance({
				method: "post",
				url: "dadoscadastraisjuridico/dados-cadastrais",
				data: dadosCadastrais,
			});
		},

		variacoesPorTermo(idTermo) {
			return instance({
				method: "get",
				url: "dadoscadastraisjuridico/variacoes-termo/" + idTermo,
			});
		},

		qtdDadosCadastraisJuridico(usuario) {
			return instance({
				method: "post",
				url: "dadoscadastraisjuridico/quantidade-status",
				data: usuario,
			});
		},

		//Publicações
		quantidadePublicacoesNaolidas(email) {
			return instance({
				method: "get",
				url: "publicacoesjuridico/qtd-nao-lidos/" + email,
			});
		},

		informacoesFiltroPublicacoes(login) {
			return instance({
				method: "post",
				url: "dadoscadastraisjuridico/informacoes-filtro-publicacoes",
				data: login,
			});
		},

		publicacoesJuridico(publicacoes) {
			return instance({
				method: "post",
				url: "publicacoesjuridico/publicacoes",
				data: publicacoes,
			});
		},

		qtdPublicacoesJuridico(publicacoes) {
			return instance({
				method: "post",
				url: "publicacoesjuridico/qtd-publicacoes-filtro",
				data: publicacoes,
			});
		},

		publicacoesPorConteudo(conteudo) {
			return instance({
				method: "post",
				url: "publicacoesjuridico/publicacoes-por-conteudo",
				data: conteudo,
			});
		},

		publicacoesPorCodigo(codigo) {
			return instance({
				method: "post",
				url: "publicacoesjuridico/publicacoes-por-codigo",
				data: codigo,
			});
		},

		salvarRascunhoFiltroPublicacoes(filtro) {
			return instance({
				method: "post",
				url: "publicacoesjuridico/salvar-rascunho-filtro",
				data: filtro,
			});
		},

		removerRascunhoFiltroPublicacoes(nomeFiltro) {
			return instance({
				method: "post",
				url:
					"publicacoesjuridico/remover-rascunho-filtro/" + nomeFiltro,
			});
		},

		recuperarRascunhoFiltroPublicacoes() {
			return instance({
				method: "get",
				url: "publicacoesjuridico/recuperar-rascunho-filtro",
			});
		},

		textoPublicacaoCompelto(codigo) {
			return instance({
				method: "post",
				url: "publicacoesjuridico/teor-publicacao",
				data: codigo,
			});
		},

		consultarStatusDiarios(login) {
			return instance({
				method: "post",
				url: "statusdiariosjuridico/status-diarios",
				data: login,
			});
		},

		consultarStatusDiariosPorFonte(loginFonte) {
			return instance({
				method: "post",
				url: "statusdiariosjuridico/status-diarios-fontes",
				data: loginFonte,
			});
		},

		consultarListaFontes() {
			return instance({
				method: "get",
				url: "statusdiariosjuridico/listar-fontes",
			});
		},

		consultarListaFontesDetalhada() {
			return instance({
				method: "get",
				url: "atendimentojuridico/listar-fontes",
			});
		},

		ExportarCadastrosJuridico(filtro) {
			return instance({
				responseType: "blob",
				method: "post",
				url: "dadoscadastraisjuridico/exportar-dados-cadastrais",
				data: filtro,
			});
		},

		ExportarPublicacoesExcelJuridico(filtro) {
			return instance({
				responseType: "blob",
				method: "post",
				url: "publicacoesjuridico/exportar-publicacoes-excel",
				data: filtro,
			});
		},

		ExportarPublicacoesJsonJuridico(filtro) {
			return instance({
				method: "post",
				url: "publicacoesjuridico/exportar-publicacoes-json",
				data: filtro,
			});
		},

		ExportarPublicacoesXmlJuridico(filtro) {
			return instance({
				responseType: "blob",
				method: "post",
				url: "publicacoesjuridico/exportar-publicacoes-xml",
				data: filtro,
			});
		},

		ExportarPublicacoesDocxJuridico(filtro) {
			return instance({
				responseType: "blob",
				method: "post",
				url: "publicacoesjuridico/exportar-publicacoes-docx",
				data: filtro,
			});
		},

		ExportarPublicacoesSelecionadasExcelJuridico(filtro) {
			return instance({
				responseType: "blob",
				method: "post",
				url: "publicacoesjuridico/exportar-publicacoes-selecionadas-excel",
				data: filtro,
			});
		},

		ExportarPublicacoesSelecionadasDocxJuridico(filtro) {
			return instance({
				responseType: "blob",
				method: "post",
				url: "publicacoesjuridico/exportar-publicacoes-selecionadas-docx",
				data: filtro,
			});
		},

		ExportarPublicacoesSelecionadasJsonJuridico(filtro) {
			return instance({
				method: "post",
				url: "publicacoesjuridico/exportar-publicacoes-selecionadas-json",
				data: filtro,
			});
		},

		ExportarPublicacoesSelecionadasXmlJuridico(filtro) {
			return instance({
				responseType: "blob",
				method: "post",
				url: "publicacoesjuridico/exportar-publicacoes-selecionadas-xml",
				data: filtro,
			});
		},

		consultarTagsJuridico(produto) {
			return instance({
				method: "get",
				url: "publicacoesjuridico/tags/" + produto,
			});
		},

		cadastrarTagJuridico(tag) {
			return instance({
				method: "post",
				url: "publicacoesjuridico/cadastrar-tag",
				data: tag,
			});
		},

		cadastrarTagPublicacoesJuridico(tag) {
			return instance({
				method: "post",
				url: "publicacoesjuridico/cadastrar-tag-publicacoes",
				data: tag,
			});
		},

		consultarTagsPublicacaoPorCodigoJuridico(codigo) {
			return instance({
				method: "get",
				url: "publicacoesjuridico/tags-publicacao/" + codigo,
			});
		},

		excluirTagJuridico(tag) {
			return instance({
				method: "post",
				url: "publicacoesjuridico/remover-tag",
				data: tag,
			});
		},

		consultarFaturamentoJuridico(filtro) {
			return instance({
				method: "post",
				url: "financeirojuridico/faturamento",
				data: filtro,
			});
		},

		consultarTermosAtendimento(filtro) {
			return instance({
				method: "post",
				url: "atendimentojuridico/termos",
				data: filtro,
			});
		},

		cadastrarNovosTermos(filtro) {
			return instance({
				method: "post",
				url: "avisos/enviaremailcadastrotermos",
				data: filtro,
			});
		},

		salvarRascunhoCadastroJuridico(rascunho) {
			return instance({
				method: "post",
				url: "dadoscadastraisjuridico/salvar-rascunho",
				data: rascunho,
			});
		},

		consultarRascunhoCadastroJuridico(usuario) {
			return instance({
				method: "get",
				url: "dadoscadastraisjuridico/rascunhos/" + usuario,
			});
		},

		excluirRascunhoCadastroJuridico(filtroExcluir) {
			return instance({
				method: "post",
				url: "dadoscadastraisjuridico/remover-rascunho",
				data: filtroExcluir,
			});
		},
	};
};
