export default ({ instance }) => {
	return {
		/// Dashboard
		ExportardadosCompletosExcelDistribuicao(filtro) {
			return instance({
				responseType: "blob",
				method: "post",
				url: "homedistribuicao/exportar-tabela-excel",
				data: filtro,
			});
		},

		listarGrupoOperacionalDistribuicao(grupoOperacional) {
			return instance({
				method: "get",
				url: "homedistribuicao/grupo-operacional/" + grupoOperacional,
			});
		},

		relatorioMesAnoPorFiltroDistribuicao(filtro) {
			return instance({
				method: "post",
				url: "homedistribuicao/mes-ano-filtro",
				data: filtro,
			});
		},

		relatorioTermoPorFiltroDistribuicao(filtro) {
			return instance({
				method: "post",
				url: "homedistribuicao/termo-filtro",
				data: filtro,
			});
		},

		relatorioGrupoOperacionalPorFiltroDistribuicao(filtro) {
			return instance({
				method: "post",
				url: "homedistribuicao/grupo-operacional-filtro",
				data: filtro,
			});
		},

		relatorioPorJusticaDistribuicao(filtro) {
			return instance({
				method: "post",
				url: "homedistribuicao/justica",
				data: filtro,
			});
		},
		relatorioPorUfDistribuicao(filtro) {
			return instance({
				method: "post",
				url: "homedistribuicao/uf",
				data: filtro,
			});
		},
		relatorioPorTermoDistribuicao(filtro) {
			return instance({
				method: "post",
				url: "homedistribuicao/termo",
				data: filtro,
			});
		},
		relatorioPorMesAnoDistribuicao(filtro) {
			return instance({
				method: "post",
				url: "homedistribuicao/mes-ano",
				data: filtro,
			});
		},

		///Dados Cadastrais
		dadosCadastraisQtdCadastros(login) {
			return instance({
				method: "get",
				url: "dadosCadastrais/qtd-cadastros/" + login,
			});
		},

		dadosCadastraisinformacoesFiltro(login) {
			return instance({
				method: "get",
				url: "dadoscadastrais/informacoes-filtro/" + login,
			});
		},

		dadosCadastraisListaFontes() {
			return instance({
				method: "get",
				url: "atendimentodistribuicao/todas-fontes",
			});
		},

		dadosCadastraisExportarCadastros(filtro) {
			return instance({
				responseType: "blob",
				method: "post",
				url: "dadoscadastrais/exportar-dados-cadastrais",
				data: filtro,
			});
		},

		dadosCadastraisCadastrosTermo(filtro) {
			return instance({
				method: "post",
				url: "dadoscadastrais/dados-cadastrais-termo",
				data: filtro,
			});
		},

		dadosCadastraisCadastrosTribunal(filtro) {
			return instance({
				method: "post",
				url: "dadoscadastrais/dados-cadastrais-fonte",
				data: filtro,
			});
		},

		variacoesPorTermoDistribuicao(idTermo) {
			return instance({
				method: "get",
				url: "dadoscadastrais/variacoes-termo/" + idTermo,
			});
		},

		salvarRascunhoCadastroDistribuicao(rascunho) {
			return instance({
				method: "post",
				url: "dadoscadastrais/salvar-rascunho",
				data: rascunho,
			});
		},

		consultarRascunhoCadastroDistribuicao(usuario) {
			return instance({
				method: "get",
				url: "dadoscadastrais/rascunhos/" + usuario,
			});
		},

		excluirRascunhoCadastroDistribuicao(filtroExcluir) {
			return instance({
				method: "post",
				url: "dadoscadastrais/remover-rascunho",
				data: filtroExcluir,
			});
		},

		///Distribuições
		quantidadeProcessosPorFiltroDistribuicao(filtro) {
			return instance({
				method: "post",
				url: "distribuicoes/qtd-processos",
				data: filtro,
			});
		},

		quantidadeProcessosNaolidos(email) {
			return instance({
				method: "get",
				url: "distribuicoes/qtd-nao-lidos/" + email,
			});
		},

		informacoesFiltroDistribuicoes(login) {
			return instance({
				method: "post",
				url: "distribuicoes/filtros-processo",
				data: login,
			});
		},

		distribuicoesProcessos(filtros) {
			return instance({
				method: "post",
				url: "distribuicoes/processos",
				data: filtros,
			});
		},

		distribuicoesProcessos2Instancia(filtros) {
			return instance({
				method: "post",
				url: "distribuicoes/processos-2instancia",
				data: filtros,
			});
		},

		distribuicoesExportarProcessosExcel(filtro) {
			return instance({
				responseType: "blob",
				method: "post",
				url: "distribuicoes/exportar-processos-excel",
				data: filtro,
			});
		},
		distribuicoesExportarProcessosSelecionadosExcel(filtro) {
			return instance({
				responseType: "blob",
				method: "post",
				url: "distribuicoes/exportar-processos-selecionados-excel",
				data: filtro,
			});
		},
		distribuicoesExportarProcessosJson(filtro) {
			return instance({
				method: "post",
				url: "distribuicoes/exportar-processos-json",
				data: filtro,
			});
		},
		distribuicoesExportarProcessosXml(filtro) {
			return instance({
				responseType: "blob",
				method: "post",
				url: "distribuicoes/exportar-processos-xml",
				data: filtro,
			});
		},
		distribuicoesExportarProcessosDocx(filtro) {
			return instance({
				responseType: "blob",
				method: "post",
				url: "distribuicoes/exportar-processos-docx",
				data: filtro,
			});
		},
		distribuicoesExportarProcessosSelecionadosDocx(filtro) {
			return instance({
				responseType: "blob",
				method: "post",
				url: "distribuicoes/exportar-processos-selecionados-docx",
				data: filtro,
			});
		},
		distribuicoesExportarProcessosSelecionadosJson(filtro) {
			return instance({
				method: "post",
				url: "distribuicoes/exportar-processos-selecionados-json",
				data: filtro,
			});
		},
		distribuicoesExportarProcessosSelecionadosXml(filtro) {
			return instance({
				responseType: "blob",
				method: "post",
				url: "distribuicoes/exportar-processos-selecionados-xml",
				data: filtro,
			});
		},

		distribuicoesExportarProcessos2InstanciaExcel(filtro) {
			return instance({
				responseType: "blob",
				method: "post",
				url: "distribuicoes/exportar-processos-excel-2instancia",
				data: filtro,
			});
		},

		distribuicoesExportarProcessos2InstanciaJson(filtro) {
			return instance({
				method: "post",
				url: "distribuicoes/exportar-processos-json-2instancia",
				data: filtro,
			});
		},
		distribuicoesExportarProcessos2InstanciaXml(filtro) {
			return instance({
				responseType: "blob",
				method: "post",
				url: "distribuicoes/exportar-processos-xml-2instancia",
				data: filtro,
			});
		},

		distribuicoesExportarProcessos2InstanciaDocx(filtro) {
			return instance({
				responseType: "blob",
				method: "post",
				url: "distribuicoes/exportar-processos-docx-2instancia",
				data: filtro,
			});
		},

		distribuicoesSalvarRascunhoFiltro(filtro) {
			return instance({
				method: "post",
				url: "distribuicoes/salvar-rascunho-filtro",
				data: filtro,
			});
		},

		distribuicoesRemoverRascunhoFiltro(nome) {
			return instance({
				method: "post",
				url: "distribuicoes/remover-rascunho-filtro/" + nome,
			});
		},

		distribuicoesRecuperarRascunhoFiltro() {
			return instance({
				method: "get",
				url: "distribuicoes/recuperar-rascunho-filtro",
			});
		},

		distribuicoesExportarProcessosSelecionados2InstanciaExcel(filtro) {
			return instance({
				responseType: "blob",
				method: "post",
				url: "distribuicoes/exportar-processos-selecionados-excel-2instancia",
				data: filtro,
			});
		},

		distribuicoesExportarProcessosSelecionados2InstanciaDocx(filtro) {
			return instance({
				responseType: "blob",
				method: "post",
				url: "distribuicoes/exportar-processos-selecionados-docx-2instancia",
				data: filtro,
			});
		},

		distribuicoesExportarProcessosSelecionados2InstanciaJson(filtro) {
			return instance({
				method: "post",
				url: "distribuicoes/exportar-processos-selecionados-json-2instancia",
				data: filtro,
			});
		},

		distribuicoesExportarProcessosSelecionados2InstanciaXml(filtro) {
			return instance({
				responseType: "blob",
				method: "post",
				url: "distribuicoes/exportar-processos-selecionados-xml-2instancia",
				data: filtro,
			});
		},

		distribuicoesDetalheProcesso(codigo) {
			return instance({
				method: "post",
				url: "distribuicoes/detalhe-processo",
				data: codigo,
			});
		},

		distribuicoesDetalheProcesso2Instancia(codigo) {
			return instance({
				method: "post",
				url: "distribuicoes/detalhe-processo-2instancia",
				data: codigo,
			});
		},

		distribuicoesProcessoPorNumero(numeroProcesso) {
			return instance({
				method: "post",
				url: "distribuicoes/processo-por-numero",
				data: numeroProcesso,
			});
		},

		distribuicoesProcessoPorNumero2Instancia(numeroProcesso) {
			return instance({
				method: "post",
				url: "distribuicoes/processo-por-numero-2instancia",
				data: numeroProcesso,
			});
		},

		consultarTagsDistribuicao(produto) {
			return instance({
				method: "get",
				url: "distribuicoes/tags/" + produto,
			});
		},
		cadastrarTagDistribuicao(tag) {
			return instance({
				method: "post",
				url: "distribuicoes/cadastrar-tag",
				data: tag,
			});
		},
		cadastrarTagProcessosDistribuicao(tag) {
			return instance({
				method: "post",
				url: "distribuicoes/cadastrar-tag-processos",
				data: tag,
			});
		},
		consultarTagsProcessosPorNumeroDistribuicao(numero) {
			return instance({
				method: "get",
				url: "distribuicoes/tags-processo/" + numero,
			});
		},
		excluirTagDistribuicao(tag) {
			return instance({
				method: "post",
				url: "distribuicoes/remover-tag",
				data: tag,
			});
		},

		///Avisos
		distribuicaoConsultarAvisos() {
			return instance({
				method: "get",
				url: "centralavisosDistribuicao/avisos",
			});
		},
		DistribuicaoconsultarFaturamento(filtro) {
			return instance({
				method: "post",
				url: "financeirodistribuicao/faturamento",
				data: filtro,
			});
		},
	};
};
